<script setup>
const backend = useBackend();

const { data: links } = await useAsyncData(async () => {
  const { data } = await backend.getFooterLinks();
  return data;
})
</script>

<template>
<div class="bg-black">
  <Container no-paddings>
    <div class="grid grid-cols-2 gap-6 text-xs text-white py-6 md:flex md:justify-between">
      <div class="col-span-2 lg:grow">&copy; VEHIS 2024</div>
      <div v-for="link in links">
        <NuxtLink :to="{name:'document', params: {slug: link.slug }}" rel="noindex">{{ link.title }}</NuxtLink>
      </div>
      <NuxtLink :to="{name:'static-information'}">Sprawozdania i komunikaty</NuxtLink>
    </div>
  </Container>
</div>
</template>
